import { atom, map, onMount, onSet } from 'nanostores'
import { timelineAcount, timelineMenu } from './animation'
import { gsap } from 'gsap'
import { lock, unlock } from 'tua-body-scroll-lock'
export const state = map({
  isMenuShow: false,
  isAccountShow: false,
})

export const setState = (name, value) => {
  state.setKey(name, value)
}

onSet(state, (value) => {
  if (value.changed === 'isAccountShow') {
    if (value.newValue.isAccountShow) {
      lock(document.querySelector('.gHeaderAccountsPanel'))
      timelineAcount.invalidate().play()
    } else {
      timelineAcount.duration(0.5).reverse()
      unlock(document.querySelector('.gHeaderAccountsPanel'))
    }
  }
  if (value.changed === 'isMenuShow') {
    if (value.newValue.isMenuShow) {
      lock(document.querySelector('.gDropdownMenuMobile'))
      timelineMenu.invalidate().play()
    } else {
      timelineMenu.invalidate().duration(0.5).reverse()
      unlock(document.querySelector('.gDropdownMenuMobile'))
    }
  }
})

onMount(state, () => {
  document.onkeydown = function (evt) {
    evt = evt || window.event
    if (evt.keyCode == 27) {
      setState('isAccountShow', false)
      setState('isMenuShow', false)
      unlock(document.querySelector('.gHeaderAccountsPanel'))
      unlock(document.querySelector('.gDropdownMenuMobile'))
    }
  }
})
const mediaWatcher = window.matchMedia('(max-width: 1280px)')

const updateTimeline = () => {
  setState('isAccountShow', false)
  setState('isMenuShow', false)
  let getTargets = timelineAcount.getChildren(true, true).map((item) => {
    return item._targets
  })
  gsap.set(getTargets.flat(), { clearProps: 'all' })
}

if (mediaWatcher.addEventListener) {
  mediaWatcher.addEventListener('change', updateTimeline)
} else {
  mediaWatcher.addListener(updateTimeline)
}
