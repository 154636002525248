import { gsap } from 'gsap'
import { state, setState } from './store'

document.addEventListener('DOMContentLoaded', () => {
  const dropdowns = Array.from(document.querySelectorAll('.gDropdown'))
  dropdowns.map((dropdown) => {
    dropdown.addEventListener('click', (e) => {
      dropdown.classList.toggle('gDropdownIsOpen')
    })
    document.addEventListener('click', (event) => {
      if (!dropdown.contains(event.target)) {
        dropdown.classList.remove('gDropdownIsOpen')
      }
    })
  })

  accountAction()
  menuAction()
})

const accountAction = () => {
  const buttons = Array.from(document.querySelectorAll('.gHeaderAccoutnButton'))
  buttons.map((button) => {
    button.addEventListener('click', (e) => {
      setState('isAccountShow', !state.get().isAccountShow)
      console.log(state.get())
    })
  })
  document.addEventListener('click', (event) => {
    const isClickInside = document
      .querySelector('.gHeaderAccountsPanel')
      .contains(event.target)
    const isClickInside2 = document
      .querySelector('.gHeaderAccoutnButton')
      .contains(event.target)
    if (!isClickInside && !isClickInside2) {
      setState('isAccountShow', false)
    }
  })
}

const menuAction = () => {
  const buttons = Array.from(document.querySelectorAll('.gHeaderMenuButton'))
  buttons.map((button) => {
    button.addEventListener('click', (e) => {
      setState('isMenuShow', !state.get().isMenuShow)
    })
  })
  document.addEventListener('click', (event) => {
    const isClickInside = document
      .querySelector('.gHeaderMenu')
      .contains(event.target)
    const isClickInside2 = document
      .querySelector('.gHeaderMenuButton')
      .contains(event.target)
    if (!isClickInside && !isClickInside2) {
      setState('isMenuShow', false)
    }
  })
}

document.onkeydown = function (evt) {
  evt = evt || window.event
  if (evt.keyCode == 27) {
    setState('isAccountShow', false)
    setState('isMenuShow', false)
  }
}
