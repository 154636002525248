import { gsap } from 'gsap'

export const timelineAcount = gsap.timeline({ paused: true })

timelineAcount.to(
  '.gHeaderAccountsPanel',
  // {
  //   y: () => {
  //     return window.matchMedia('(max-width: 1024px)').matches ? 0 : 30
  //   },
  //   x: () => {
  //     return window.matchMedia('(max-width: 1024px)').matches ? '120%' : 0
  //   },
  //   opacity: () => {
  //     return window.matchMedia('(max-width: 1024px)').matches ? 1 : 0
  //   },
  //   visibility: () => {
  //     return window.matchMedia('(max-width: 1024px)').matches
  //       ? 'visibility'
  //       : 'hidden'
  //   },
  //   ease: 'power2.out',
  // },
  {
    y: 0,
    x: 0,
    opacity: 1,
    visibility: 'visible',
    ease: 'power2.out',
  },
  0
)

timelineAcount.fromTo(
  '.gHeaderAccountProfileItem',
  {
    y: 10,
    opacity: 0,
  },
  {
    y: 0,
    opacity: 1,
    stagger: 0.05,
    ease: 'power4.inOut',
  },
  '<.35 '
)

export const timelineMenu = gsap.timeline({ paused: true })

const menuElements = [
  ...Array.from(document.querySelectorAll('.gHeaderMenuItem')),
  ...Array.from(document.querySelectorAll('.gDropdownMenuMobile')),
]
const mm = gsap.matchMedia()

mm.add('(max-width: 1279px)', () => {
  timelineMenu.fromTo(
    '.gHeaderMenu',
    {
      x: '-130%',
      opacity: 0,
      visibility: 'hidden',
      ease: 'power2.out',
    },
    {
      x: 0,
      opacity: 1,
      visibility: 'visible',
      ease: 'power2.out',
    },
    0
  )
  timelineMenu.fromTo(
    menuElements,
    {
      y: 10,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      stagger: 0.05,
      ease: 'power4.inOut',
    },
    '<.35 '
  )
})
